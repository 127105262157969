import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import sl from 'vuetify/es5/locale/sl'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },

    lang: {
        locales: { sl },
        current: 'sl',
    },

    theme: {
        themes: {
            /*light: {
                primary: colors.blue.darken3,
                secondary: colors.blue.darken2,
                tertiary: colors.indigo.lighten2,
                accent: '#8c9eff',
                error: '#b71c1c',
                background: '#fafafa'
            },*/
            light: {
                primary: "#29508B",
                secondary: "#29508B",
                tertiary: "#29508B",
                accent: '#8c9eff',
                error: '#b71c1c',
                background: '#fafafa'
            }
        },

        dark: false
    },
});
