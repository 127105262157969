var CryptoJS = require('crypto-js')

export default {
    SET_USER(state, data) {
    if(data == null)
    {
        state.user.id = null
        state.user.name = null
        state.user.email = null
        state.user.role = null
        state.user.last_seen = null
        state.user.access_token = ""
        state.user.application_role = null
        state.user.application_role_name = null
        state.user.application_permissions = []
    }
    else
    {
        state.user.id = data.id
        state.user.name = data.name
        state.user.email = data.email
        state.user.role = data.role
        state.user.last_seen = data.last_seen
        state.user.access_token = data.access_token
        if('application_role' in data) {
            state.user.application_role = data.application_role
        }

        if('application_role_name' in data) {
            state.user.application_role_name = data.application_role_name
        }

        if('application_permissions' in data) {
            state.user.application_permissions = data.application_permissions
        }
    }

        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state.user), process.env.VUE_APP_CRYPTO_JS_KEY).toString();
        //window.console.log("ciphertext: ");
        //window.console.log(ciphertext);

        // Decrypt
        var bytes  = CryptoJS.AES.decrypt(ciphertext, process.env.VUE_APP_CRYPTO_JS_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        //window.console.log("decrypted data:")
        //window.console.log(decryptedData)

        //localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(state.user))
        localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(ciphertext))
    },

    SET_DRAWER (state, value) {
        state.drawer = value
    },

    SET_TOOLBAR_TITLE (state, title) {
        state.toolbarTitle = title
    },

    SET_LOADER (state, value) {
        state.loader = value
    },

    SET_LOADER_TEXT (state, text) {
        state.loaderText = text
    },

    SET_SEARCH (state, search) {
        state.search = search
    },

    SET_SEARCH_FIELD_STATE (state, fieldState) {
        state.searchFieldState = fieldState
    },

    SET_MOBILE_SEARCH (state, mobileSearchState) {
        state.mobileSearch = mobileSearchState
    },

    SET_PROGRESS (state, value) {
        state.progress = value
    },

    SET_CURRENT_ROUTE (state, routeName) {
        state.currentRoute = routeName
    },

    SET_SNACKBAR_TEXT (state, text) {
        state.snackbar.text = text
    },

    SET_SNACKBAR_DISPLAY (state, display) {
        state.snackbar.display = display
    },
    
    SET_SNACKBAR_Y (state, y) {
        state.snackbar.y = y
    },

    SET_SNACKBAR_X (state, x) {
        state.snackbar.x = x
    },

    SET_SNACKBAR_MODE (state, mode) {
        state.snackbar.mode = mode
    },

    SET_SNACKBAR_TIMEOUT (state, timeout) {
        state.snackbar.timeout = timeout
    },

    setSearch (state, search) {
        state.search = search
    },

    setSearchFieldState(state, fieldState) {
        state.searchFieldState = fieldState
    },

    snackbarDisplay (state, display) {
        state.snackbar.display = display
    },

    snackbarY (state, y) {
        state.snackbar.y = y
    },

    snackbarX (state, x) {
        state.snackbar.x = x
    },

    snackbarMode (state, mode) {
        state.snackbar.mode = mode
    },

    snackbarTimeout (state, timeout) {
        state.snackbar.timeout = timeout
    },

    snackbarText (state, text) {
        state.snackbar.text = text
    },

    /*
    storeDataTableItemsPerPage(state, obj) {
        //window.console.log(obj)
        state.user.app_settings[obj.key].items_per_page = obj.value

        let user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
        if (user) {
            let bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
            let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            user = decryptedData

            //window.console.log(user)
            user.app_settings[obj.key].items_per_page = obj.value

            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), process.env.VUE_APP_CRYPTO_JS_KEY).toString();
            localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(ciphertext))
        }
    }
    */

    SET_DEFAULT_APP_SETTINGS(state, obj) {
        state.app_settings = obj
        localStorage.setItem(process.env.VUE_APP_SETTINGS_KEY, JSON.stringify(obj))
    },

    SET_STATE_APP_SETTINGS(state, obj) {

        let stateSettings = state.app_settings
        let localStorageSettings = obj

        for(const ss in stateSettings) {
            if(!(ss in localStorageSettings)) {
                window.console.log(ss)
                localStorageSettings[ss] = stateSettings[ss]
            }
        }


        state.app_settings = localStorageSettings

        //window.console.log(state.app_settings)
        //window.console.log(obj)
    },

    APP_SETTING(state, obj) {
        //window.console.log(obj)
        state.app_settings[obj.setting][obj.key] = obj.value

        let as = state.app_settings;
        //window.console.log(as)

        localStorage.setItem(process.env.VUE_APP_SETTINGS_KEY, JSON.stringify(as))

    }
}