export default {
    app: {
        drawerTitle: process.env.VUE_APP_DRAWER_TITLE,
        title: process.env.VUE_APP_TITLE,
        shortTitle: process.env.VUE_APP_SHORT_TITLE,
        icon: 'language',
        appKey: process.env.VUE_APP_KEY
    },

    user: {
        id: null,
        name: null,
        email: null,
        role: null,
        permissions: null,
        access_token: null,
        last_seen: null,
        application_role: null,
        application_role_name: null,
        application_permissions: []
    },

    app_settings: {
        dt_events: {
            items_per_page: 10,
            columns: [],
        },

        dt_users: {
            items_per_page: 10,
            columns: []
        },

        dt_parking_barriers_archive: {
            items_per_page: 10,
            columns: []
        },

        dt_parking_occupancy: {
            items_per_page: null,
            columns: []
        },

        dt_registration_numbers: {
            items_per_page: 10,
            columns: []
        },

        dt_exports: {
            items_per_page: 10,
            columns: []
        },

        events: {
            filter_results_open: true
        },

        users: {
            filter_results_open: true,
        },

        parking_barriers_archive: {
            filter_results_open: true
        },

        registration_numbers: {
            filter_results_open: true,
            validation_open: true
        },

        parking_occupancy: {
            filter_results_open: true
        }
    },

    drawer: null,
    loader: false,
    progress: false,
    loaderText: '',
    toolbarTitle: '',
    search: '',
    searchFieldState: false,
    mobileSearch: false,

    snackbar: {
        display: false,
        y: 'bottom',
        x: 'left',
        mode: '',
        timeout: 7000,
        text: 'Hello, I\'m a snackbar'
    },

    access_log: [],
    currentRoute: null
}