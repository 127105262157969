<template>
    <v-app id="inspire">
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                <v-flex xs12 sm8 md8 lg4>
                    <v-card class="elevation-5 pa-3">
                    <v-card-text>
                        <div class="layout column align-center"><br>
                        <img :src="getLogo" alt="Nadzorni center - Prijava"  height="auto" :width="($vuetify.breakpoint.smAndDown) ? '60%' : '35%;'">
                        <br><br>
                        <div>
                            <p class="headline font-weight-medium text-center">Nadzorni center</p>
                        </div>
                        <div>
                            <p class="title font-weight-medium text-center">Prijava</p>
                        </div>
                        <v-layout align-center justify-center="">
                            <v-flex xs12 sm12 md12 lg12>
                                <div class="">
                                    <p style="text-align:center;" class="font-weight-medium">Vnesite elektronski naslov in geslo za vstop v sistem.</p>
                                </div>
                            </v-flex>
                        </v-layout>
                        </div>                
                        <br>
                        <v-form v-if="loginForm" ref="loginForm" v-model="valid" lazy-validation>
                        <v-text-field
                            append-icon="mdi-email"
                            name="login" label="E-mail" 
                            type="email" 
                            :rules="emailRules" 
                            v-model="credentials.username"
                            :error="error"
                            :error-messages="errors['message']"
                            @keyup.enter="login"
                            color="tertiary"
                            >
                        </v-text-field>
                        <v-text-field id="password" append-icon="mdi-lock" name="password" label="Geslo" type="password" :rules="passwordRules" v-model="credentials.password" @keyup.enter="login" color="tertiary"></v-text-field>

                        <!-- H o n e y p o t -->
                        <label class="ohnohoney" for="name"></label>
                        <input class="ohnohoney" v-model="hpot.name" autocomplete="off" type="text" id="name" name="name" placeholder="Your name here">
                        <label class="ohnohoney" for="email"></label>
                        <input class="ohnohoney" v-model="hpot.email" autocomplete="off" type="email" id="email" name="email" placeholder="Your e-mail here">
                        </v-form>
                        <v-card-text v-else>
                            <v-layout align-center justify-center>
                                <v-flex xs12 sm12 md12 lg12>
                                    <div class="text-center">
                                        <v-btn v-if="!$vuetify.breakpoint.xsOnly" color="secondary" @click="displayLoginForm()" :loading="loginFormLoading">Prijava v sistem</v-btn>
                                        <v-btn v-else color="secondary" block @click="loginForm = true">Prijava v sistem</v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card-text>
                    <span v-if="loginForm">
                        <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                            <v-spacer></v-spacer>
                            <v-btn v-if="loginForm" class="white--text" dark color="secondary" @click.native="login" :loading="loginLoading">Prijava</v-btn>
                            <v-btn v-if="loginForm" class="white--text" dark color="secondary" @click.native='passwordReset'>Pozabljeno geslo?</v-btn>
                            <br><br>
                        </v-card-actions>
                        <v-card-text v-else>
                            <v-btn v-if="loginForm" block outlined  style="margin-bottom:10px;" class="white--text" dark color="secondary" @click.native="login" :loading="loginLoading">Prijava</v-btn>
                            <v-btn v-if="loginForm" outlined block class="white--text" dark color="secondary" @click.native='passwordReset'>Pozabljeno geslo?</v-btn>
                        </v-card-text>
                    </span>
                    <br v-if="!loginForm">
                    </v-card>
                </v-flex>
                </v-layout>
            </v-container>
            <network-status></network-status>
        </v-main>
    </v-app> 
</template>

<script>
import router from '@/router'
import { post } from '@/helpers/api'
import logo from '@/assets/logo.png'
import { getUserApplicationRole, getUserApplicationPermissions, checkPermissions } from '@/helpers/utilities'
const NetworkStatus = () =>import('@/components/NetworkStatus')

export default {
    name: 'login',
    props: {
        source: String
    },

    components: {
        NetworkStatus
    },

    data: () => ({
        title: 'Nadzorni Center - Garažna hiša Zaton',
        valid: true,
        avatarSize: 32,
        tile: true,
        drawer: null,
        credentials: {
            username: '',
            password: ''
        },

        hpot: {
            name: null,
            email: null
        },

        loginForm: false,
        loginFormLoading: false,

        emailRules: [
            (v) => !!v || 'Elektronski naslov je obvezen.',
            (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Vneseni elektronski naslov ni veljaven.'
        ],
        passwordRules: [
            (v) => !!v || 'Geslo je obvezno.',
            (v) => v.length >= 6 || 'Geslo mora vsebovati vsaj 8 mestni zapis.'
        ],

        loginLoading: false,
        error: false,
        errors: {},

        timeout1: null
    }),

    computed: {
        getLogo() {
            return logo
        }
    },

    methods: {
        login() {
            //window.console.log(this.hpot)
           if(this.$refs.loginForm.validate() && ((this.hpot.name == null || this.hpot.name == "") && (this.hpot.email == null || this.hpot.email == ""))) {
            this.loginLoading = true;
            this.error = false;
            this.errors = {}
            this.credentials.grant_type = process.env.VUE_APP_GRANT_TYPE;
            this.credentials.client_id = process.env.VUE_APP_CLIENT_ID;

            this.$store.dispatch('LOGIN', this.credentials)
              .then(response => {
                //window.console.log(response)
                let userApplicationRole = getUserApplicationRole();

                if(userApplicationRole == 'editor' || userApplicationRole == 'admin') {
                    //window.console.log("redirec to to validator");
                    return router.push({
                        name: 'dashboard'
                    })
                }

                if(userApplicationRole == 'operator') {
                    //window.console.log("redirec to to validator");
                    return router.push({
                        name: 'parking_barriers_management'
                    })
                }

                return router.push({ name: 'dashboard'});

              })
              .catch(error => {
                window.console.log("Error")
                window.console.log(error.response)
                window.console.log(error)
                this.error = true;
                this.loginLoading = false;
                if(error.response.status == 401) {
                  this.errors = { message: "Napačno uporabniško ime ali geslo." }
                }
                else {
                  this.errors = { message: "Pri prijavi v sistem je prišlo do napake."}
                }
              })
              .then(() => {
                this.loginLoading = false;
              })
            } 
        },

        passwordReset() {
            this.$router.push({
                name: 'passwordResetRequest'
            })
        },

        displayLoginForm() {
            this.loginFormLoading = true
            this.timeout1 = setTimeout(() => {
                this.loginFormLoading = false
                this.loginForm = true

            }, 400)
        }
    },

    created() {

    },

    beforeDestroy() {
        clearTimeout(this.timeout1)
    }
}

</script>

<style scoped>
    #inspire{
        background-image: url("../../assets/bckpattern.png") !important;
        background-repeat: repeat !important;
    }

    .ohnohoney{
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }
</style>